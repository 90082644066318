import React from "react";
import Button from "antd/es/button";
import Select from "antd/es/select";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { range } from "underscore";
import { monthFilters, quarterFilters } from "./constants";
import { Period } from "./utils";

const { Option } = Select;

const dayjs = require("dayjs");

dayjs().format();

function PeriodSelect({ period, onSelect, onCheck, contract }) {
  const onPrev = () => onSelect(period.prev());
  const onNext = () => onSelect(period.next());

  const prevAvailable = onCheck(period.prev());
  const nextAvailable = onCheck(period.next());

  function renderMonthFilter() {
    if (contract && contract.settlement_period === "QUARTERLY") {
      return quarterFilters.map((quarter) => (
        <Option key={quarter.id} value={quarter.formatted}>
          {quarter.month}
        </Option>
      ));
    }

    return monthFilters.map((month) => (
      <Option key={month.id} value={month.formatted}>
        {month.month}
      </Option>
    ));
  }

  function renderYearFilter() {
    if (contract && contract.settlement_period === "QUARTERLY") {
      return range(2024, dayjs().year() + 2).map((year) => (
        <Option key={year}>{year}</Option>
      ));
    }

    return range(2021, dayjs().year() + 2).map((year) => (
      <Option key={year}>{year}</Option>
    ));
  }

  function onDropdownSelect(year, month) {
    onSelect(Period.fromString(`${year}/${month}`));
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: 20,
      }}
    >
      <Button onClick={onPrev} disabled={!prevAvailable}>
        <LeftOutlined />
      </Button>
      <Select
        value={period.year}
        style={{ width: 120 }}
        onChange={(year) => onDropdownSelect(year, period.month.formatted)}
      >
        {renderYearFilter()}
      </Select>
      <Select
        value={period.month.month}
        style={{ width: 120 }}
        onChange={(month) => onDropdownSelect(period.year, month)}
      >
        {renderMonthFilter()}
      </Select>
      <Button onClick={onNext} disabled={!nextAvailable}>
        <RightOutlined />
      </Button>
    </div>
  );
}

export default PeriodSelect;
