import React from "react";
import Empty from "antd/es/empty";
import Descriptions from "antd/es/descriptions";

export default function SettlementRules({ rental, contract, rentalShare }) {
  return contract && rental ? (
    <div style={{ maxWidth: 750 }}>
      <br />
      <Descriptions
        title={`Zasady rozliczenia miesiąca dla apartamentu ${rental.name}`}
        layout="horizontal"
        column={1}
        bordered
      >
        {contract.owners_take > 0 && (
          <Descriptions.Item
            label={`Procentowy udział w przychodzie ${
              contract.owners_from == "GROSS" ? "brutto" : "netto"
            } z rezerwacji`}
          >
            {" "}
            {contract.owners_take * 100} %{" "}
          </Descriptions.Item>
        )}
        {contract.commission_ratio && (
          <Descriptions.Item label="Procentowy udział Właściciela w opłacaniu prowizji portali">
            {" "}
            {contract.commission_ratio * 100} %{" "}
          </Descriptions.Item>
        )}
        {contract.fixed_profit && (
          <Descriptions.Item label="Stały czynsz miesięczny">
            {" "}
            {contract.fixed_profit} PLN{" "}
          </Descriptions.Item>
        )}
        {contract.daily_profit && (
          <Descriptions.Item label="Stały czynsz dzienny">
            {" "}
            {contract.daily_profit} PLN{" "}
          </Descriptions.Item>
        )}
        {contract.minimum_profit && (
          <Descriptions.Item label="Minimalny czynsz">
            {" "}
            {contract.minimum_profit} PLN{" "}
          </Descriptions.Item>
        )}
        {rentalShare > 0 && rentalShare !== 1 && (
          <Descriptions.Item label="Procentowy udział Właściciela w apartamentcie">
            {" "}
            {rentalShare * 100} %{" "}
          </Descriptions.Item>
        )}
        {rental.settlement_period && (
          <Descriptions.Item label="Tryb rozliczenia">
            {" "}
            {rental.settlement_period == "MONTHLY"
              ? "Miesięczne"
              : "Kwartalne"}{" "}
          </Descriptions.Item>
        )}
      </Descriptions>
    </div>
  ) : (
    <Empty
      className="empty-sign"
      description="Brak informacji o zasadach rozliczenia."
    />
  );
}
